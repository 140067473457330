// import { LitElement, html, css } from 'lit-element'
// import FirebaseLoginUiClass from './firebase-login-ui'
//
//函數
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import { getFirebaseConfig } from './firebase-config.js';
import { initFirebaseAuth,loadMessages,signOutUser,signInByGoogle} from './firebase-function.js'
import {subscriberByEmail,subscriberByEmail2} from './js/subscriber-api.js';

//
// customElements.define('firebase-login-ui', FirebaseLoginUiClass);

window.onload = (event) => {
   var subscribeResult={};
   // subscribeResult= subscriberByEmail2("jintenglinong@gmai.com")
     //初始化賬號登錄
     const firebaseAppConfig = getFirebaseConfig();
     // TODO 0: Initialize Firebase
     initializeApp(firebaseAppConfig);
     // TODO 12: Initialize Firebase Performance Monitoring
     initFirebaseAuth();
     loadMessages();
     // TODO: Enable Firebase Performance Monitoring.
     getPerformance();
     //  
     
  };

// async function test1(){
//    var subscribeResult={};
//    subscribeResult= subscriberByEmail2("jintenglinong@gmai.com")
//    console.log(subscribeResult)
// }
// var yourSubscribeTableele=document.getElementById('test')
// yourSubscribeTableele.addEventListener('click',()=>{
//    test1()
// })